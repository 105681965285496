import React from 'react';
import { Link } from 'gatsby'
import Article from '../../components/Article'
import "../../components/App.css"
import "../../components/ArticleContent.css"

export default function BlogObesity() {
	
	return (
		<Article
			title='My experience with weight loss'
			takeaway_title='Make following changes to lose excess weight'
			page_id='Blog - Obesity'
			read_time='9 mins'
			author='Brij Bhushan'
			image1='/before-after.jpg'
			image1_description='The journey from 89 kgs to 67 kgs'
			image2='/pic-from-conf.jpeg'
			image2_description='This is the best pic from the conference that I had to settle with'
			toggle_article='Solution'
			toggle_url='/article/soln_obesity'
			related_article='Solution - Obesity'
			takeaway_data={TakeawayData}
			related_articles={RelatedArticles}
		>
			<div className='article_container'>
					<p className='article_para'>
						It was the end of a long year. Throughout the year I was busy at work. In evenings, nights & weekends I was occupied with preparing presentation material for various conferences where I participated. I was going through my pictures from the last conference where I gave a presentation. I wanted to post a pic on Facebook & LinkedIn. It was difficult to find a good picture because it was evident that I had gained lots of weight during the year.
					</p>
					<p className='article_para'>
						I checked my weight. It was 89 kgs. With a height of 5’10”, BMI was 28.2. According to the BMI recommendation for Indians, the healthy range is 18.0-22.9. Between 23.0-24.9 is overweight. More than 25 is obese. I have been overweight since I graduated but now I was obese. It has never been worse. I decided that I have to bring my weight at least down to 79 kg, so that I am at least back in the overweight bracket. At the beginning, it looked like an impossible task but I decided to take a few baby steps, to set the ball rolling.
					</p>
					<p className='article_para'>
						I checked the timings of the local gym of our housing society and decided to start going there thrice a week for one-hour workout at 6 PM, after my work. I didn’t wait till 1st Jan to start with this resolution, rather my first day in the gym was on 23rd Dec itself. I started with some simple exercises like 20 mins cardio on treadmill, followed by lat down, chest pecs, etc & 15 mins abs floor-exercises. It was not my first time in the gym. I had followed the routine of working out in the gym thrice a week earlier also, but I broke that routine 2 yrs back when I took up this new challenging job. I knew from my past experience with gym that even if I scale up to intense workout I can lose max 5 kgs. In order to lose 10 kgs, I will have to do something more. So, I decided to cut down my sugar intake. This means I stopped having chocolates, icecreams and indian sweets. I continued occasionally having cookies, cakes & other desserts.
					</p>
					<p className='article_para'>
						While working out in the gym, it was evident that my body had turned quite stiff & was in its worst shape at the age of 42 yrs. For the next 2 months, I continued with this routine and my weight was still the same. I had decided not to compromise on my health even if I fail to grab a few brownie points at work. So, I continued working out in the gym thrice a week, along with avoiding extra sugar intake. From the 3rd month, my weight started reducing. Soon I was losing almost half a kg every week. The trend continued till the end of 5th month. By then I had accomplished my target of losing 10 kgs and my weight was 79 kgs.
					</p>
					<p className='article_para'>
						At this time I got really excited with my accomplishment. I joined a bigger professional gym and started going to the gym daily. Based on the initial assessment of the gym, I still had extra mass in my trunk area and my weight was still far from the normal range. I was quite pepped up and started scaling up my workout with advice from trainers. In the next few months, I did lose 2 kgs more but occasionally I started having injuries in my body. Sometimes it was nervous fatigue due to excess functional training, or pain behind the knee while on the treadmill and on other times a spasm in shoulder or neck. Once I had such a bad spasm that a physiotherapist advised me to discontinue the gym for a couple of months. I realized that I can’t push my body the way I used to push it in my 30s.
					</p>
					<p className='article_para'>
						While I started rehabilitation of my muscles with the physiotherapist, I decided to do research on this topic myself as well. First I read a few medical papers published on the NIH website. Then I read a book written by a physiotherapist. Eventually, I saw a few videos on YouTube and started reading Ayurveda texts as well. In research, we pull bits and pieces of information from different sources, double check them across the sources and build a holistic picture. In my research of health, eventually I could clearly see a holistic picture emerging from multiple medical streams that apart from exercise, a major role is played by diet and lifestyle. The knowledge I gathered from diverse sources like western medicine, physiotherapy and Ayurveda, helped me build a holistic understanding of how the human body works. From western medicine I learnt concepts like BMI, Insulin Resistance, difference among glucose, fructose & sucrose, among various other things. From physiotherapy & nutrition, I learnt about how muscle mass is different from fat mass and the role of different vitamins and trace minerals. Ayurveda helped me understand how diet and the way we consume our food affects the body. Mostly, all medical streams helped me build a single holistic picture. However, a few times there were differences. In those cases, I tried the recommendations on myself for a couple of months and eventually took my own call.
					</p>
					<p className='article_para'>
						Based on the research, I started complying to the following 8 principles:
						<ol className='article_list'>
							<li>No refined white sugar</li>
    						<li>Not drinking water before & after meals</li>
    						<li>No snacking between meals</li>
    						<li>Properly chewing the food</li>
    						<li>No alcohol</li>
    						<li>No fruit juice</li>
    						<li>Good sleep at night</li>
    						<li>Regular exercise</li>
    					</ol>
						(For explanation of each principle and details on their rationale & references, one should refer to my other article on the weight-loss solution <Link to='/article/soln_obesity'>here</Link>)
					</p>
					<p className='article_para'>
						For the next 2 yrs, I stayed away from the gym. However, at home itself I used to do basic exercises for stretching and strengthening of muscles in the shoulder & neck area. Along with that I started taking walks for a total of 45 mins everyday. Just by complying to these 8 principles, I observed that once again slowly and gradually, I started losing weight. This time the weight loss was completely effortless and with no adverse affect on my health. During the 2 years, I lost another 12 kgs and my weight was 67 kgs. According to the BMI recommendations for Indians, it is the perfect weight for my height.
					</p>
					<p className='article_para'>
						After that I have continued my compliance with these 8 principles. From last 1 year my weight has remained constant within the range of 65-67 kgs. Basically, once my weight reached the optimal level it didn’t drop further and has stabilized there.
					</p>
					<p className='article_para'>
						It is said that people try to lose weight to get healthy, whereas one has to get healthy to lose weight. With my experience, I could completely relate with this statement because with these changes in my diet & lifestyle, losing weight was only one of the changes in my body. With these changes my immunity also became stronger. My old problems related to acne went away automatically and the frequency of catching cold also came down significantly.
					</p>
			</div>
		</Article>
	);

}

const TakeawayData = [
	{
		takeaway: "Don't consume sugar & products that contain sugar",
	},
	{
		takeaway: "Don't drink water before & after meals",
	},
	{
		takeaway: "Don't eat snacks between meals",
	},
	{
		takeaway: "Chew your food properly",
	},
	{
		takeaway: "Don't consume alcohol",
	},
	{
		takeaway: "Don't drink fruit juice",
	},
	{
		takeaway: "Have sufficient sleep at night",
	},
	{
		takeaway: "Exercise regularly",
	},
];

const RelatedArticles = [
	{
		title: 'Solution - Obesity',
		path: '/article/soln_obesity',
	},
];